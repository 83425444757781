input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #649e93;
  width: 50px;
  height: 50px;
  text-align: right;
  display: block;
  line-height: 150%;
  font-size: .85em;
  position: absolute;
  right: 0;
  left: 73%;
}

.couple-img-p {
  margin-bottom: 0;
}

.custom-file-upload {
  margin: 15px 5px 5px 5px;
  background: #b18272;
  width: 50px;
  height: 50px;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  font-size: 1.125rem;
  color: #649e93;
  border: 0;
  outline: 0;
  position: absolute;
  right: 0;
}

.custom-file-upload i {
  color: #fff;
  font-size: 28px;
}

.blog-sidebar textarea {
  background-color: rgba(178, 201, 211, .3);
  height: 80px;
  width: 100%;
  font-size: 17px;
  font-size: 1.0625rem;
  padding: 6px 50px 6px 20px;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
  margin-top: 10px;
}

.comment-area p {
  margin-top: 9px;
}

.admin-sidebar {
  height: 845px;
  overflow-y: scroll;
}

.form-textarea {
  margin-top: 10px;
}

.bartoggle:active {
  pointer-events: none;
}