.fancybox-hidden {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}


.delete-btn {
  position: relative;
  left: 98%;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background: #b18272;
  color: #fff;
  cursor: pointer;
  font-size: 10px;
  line-height: 10px
}
