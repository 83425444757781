.Gallery-section {
    background: rgba(178, 201, 211, .1);
}

.gallery-img {
    max-width: 600px;
    cursor: pointer;
    margin-bottom: 30px;
    overflow: hidden;
}

.delete-btn {
    position: relative;
    left: 98%;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    background: #b18272;
    color: #fff;
    cursor: pointer;
    font-size: 10px;
    line-height: 10px
}

.image-box img {
    object-fit: cover;
}

.gallery-img img {
    width: 100%;
    height: 470px;
    object-fit: cover;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
}

.gallery-img:hover img {
    -webkit-transform: scale(1.2) rotate(5deg);
    -ms-transform: scale(1.2) rotate(5deg);
    transform: scale(1.2) rotate(5deg);
}

.gallery-img:hover .image-box img {
    transform: none;
}