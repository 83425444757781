.page-title {
  background: url("../../images/1.jpg");
  height: 500px;
  text-align: center;
  position: relative;
}

.page-title:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.page-title .container {
  height: 100%;
  display: table;
}

.page-title .container>.row {
  vertical-align: middle;
  display: table-cell;
}

/* Estilos para pantallas de computadora */
.wedding-id-form {
  width: 378px;
  margin: auto;
}

/* Estilos para pantallas de teléfono */
@media screen and (max-width: 768px) {
  .wedding-id-form {
    width: 200px;
  }
}


.contact-form {
  margin-right: 20px;
}

.row-guest {
  vertical-align: middle;
  display: table-cell;
  display: flex;
  flex-wrap: inherit;
  margin-left: -25px;
}

#delete-guest {
  width: 30px;
  height: 30px;
  padding-right: 20px;
  background: #B2CFC9;
  color: #fff;
  border-radius: 100%;
  margin-top: 25px;
  margin-left: -15px;
  cursor: pointer;
  font-size: 12px;
  line-height: 15px;
}

.page-title h2,
.page-title ol {
  color: #fff;
}

.page-title h2 {
  font-size: 40px;
  font-size: 2.5rem;
  margin-bottom: 0.30em;
  text-transform: capitalize;
  margin-top: 80px;
}

@media (max-width: 991px) {
  .page-title h2 {
    font-size: 35px;
    font-size: 2.1875rem;
  }
}

@media (max-width: 767px) {
  .page-title h2 {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

.page-title .breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .page-title .breadcrumb {
    text-align: center;
  }
}

.page-title .breadcrumb li {
  font-size: 16px;
  font-size: 1rem;
  color: #649e93;
  margin-right: 5px;
  position: relative;
}

@media (max-width: 767px) {
  .page-title .breadcrumb li {
    font-size: 14px;
    font-size: 0.875rem;
  }

  .page-title {
    height: 280px;
  }
}

.page-title .breadcrumb li a {
  color: #fff;
}

.page-title .breadcrumb li a:hover {
  color: #649e93;
}

.page-title .breadcrumb>li+li {
  margin-left: 20px;
  padding-left: 15px;
}

.page-title .breadcrumb>li+li:before {
  content: "/";
  color: #fff;
  padding: 0;
  position: absolute;
  left: -8px;
  top: 2px;
}

.breadcrumb {
  justify-content: center;
}

@media only screen and (max-width: 991px) {
  .page-title .container>.row {
    display: none;
  }
}