.guestlist-form-area {
    background: linear-gradient(rgba(234, 227, 209, 0.50), rgba(234, 227, 209, 0.50)), transparent center center/cover no-repeat fixed;
    padding: 100px 0;
    position: relative;
    background-attachment: fixed;
    z-index: 1;
    /* height: 892px; */
    width: 100%;
}

.container {
    width: 100%;
    padding-right: 5px !important;
    padding-left: 5px !important;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

@media only screen and (max-width: 991px) {
    .no-padding-mob {
        padding: 0%;
    }

    .row {
        display: block;
    }
}

