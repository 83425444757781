/* @font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon/icomoon.eot?srf3rx");
  src: url("../fonts/icomoon/icomoon.eot?srf3rx#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon.ttf?srf3rx") format("truetype"), url("../fonts/icomoon/icomoon.woff?srf3rx") format("woff"), url("../fonts/icomoon/icomoon.svg?srf3rx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
} */
/* =======================================================
*
* 	Template Style 
*
* ======================================================= */
/* body {
  font-family: "Work Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  color: #828282;
  background: #fff;
} */

@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&family=Source+Sans+Pro:wght@400;700&display=swap');

#page {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.offcanvas #page {
  overflow: hidden;
  position: absolute;
}

.offcanvas #page:after {
  -webkit-transition: 2s;
  -o-transition: 2s;
  transition: 2s;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.7);
  content: "";
}

a {
  /* color: #F14E95; */
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  /* color: #F14E95; */
  outline: none;
  text-decoration: none;
}

p {
  margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
figure {
  color: #000;
  /* font-family: "Work Sans", Arial, sans-serif; */
  font-weight: 400;
  margin: 0 0 20px 0;
}

::-webkit-selection {
  color: #fff;
  /* background: #F14E95; */
}

::-moz-selection {
  color: #fff;
  /* background: #F14E95; */
}

.fh5co-nav {
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  padding: 40px 0;
  z-index: 1001;
}

@media screen and (max-width: 768px) {
  .fh5co-nav {
    padding: 20px 0;
  }
}

.fh5co-nav #fh5co-logo {
  font-size: 40px;
  margin: 0;
  padding: 0;
  line-height: 40px;
  /* font-family: "Sacramento", Arial, serif; */
}

.fh5co-nav a {
  padding: 5px 10px;
  color: #fff;
}

@media screen and (max-width: 768px) {

  .fh5co-nav .menu-1,
  .fh5co-nav .menu-2 {
    display: none;
  }
}

.fh5co-nav ul {
  padding: 0;
  margin: 2px 0 0 0;
}

.fh5co-nav ul li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline;
}

.fh5co-nav ul li a {
  font-size: 14px;
  padding: 30px 10px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.fh5co-nav ul li a:hover,
.fh5co-nav ul li a:focus,
.fh5co-nav ul li a:active {
  color: white;
}

.fh5co-nav ul li.has-dropdown {
  position: relative;
}

.fh5co-nav ul li.has-dropdown .dropdown {
  width: 130px;
  -webkit-box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  z-index: 1002;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 40px;
  left: 0;
  text-align: left;
  background: #fff;
  padding: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}

.fh5co-nav ul li.has-dropdown .dropdown:before {
  bottom: 100%;
  left: 40px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}

.fh5co-nav ul li.has-dropdown .dropdown li {
  display: block;
  margin-bottom: 7px;
}

.fh5co-nav ul li.has-dropdown .dropdown li:last-child {
  margin-bottom: 0;
}

.fh5co-nav ul li.has-dropdown .dropdown li a {
  padding: 2px 0;
  display: block;
  color: #999999;
  line-height: 1.2;
  text-transform: none;
  font-size: 15px;
}

.fh5co-nav ul li.has-dropdown .dropdown li a:hover {
  color: #000;
}

.fh5co-nav ul li.has-dropdown:hover a,
.fh5co-nav ul li.has-dropdown:focus a {
  color: #fff;
}

.fh5co-nav ul li.btn-cta a {
  /* color: #F14E95; */
}

.fh5co-nav ul li.btn-cta a span {
  background: #fff;
  padding: 4px 20px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  display: inline;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
}

.fh5co-nav ul li.btn-cta a:hover span {
  -webkit-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
}

.fh5co-nav ul li.active>a {
  color: #fff !important;
}

#fh5co-counter,
#fh5co-event,
.fh5co-bg {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
}

.fh5co-bg {
  background-position: center center;
  width: 100%;
  float: left;
  position: relative;
}

.fh5co-video {
  height: 450px;
  overflow: hidden;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}

.fh5co-video a {
  z-index: 1001;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -45px;
  margin-left: -45px;
  width: 90px;
  height: 90px;
  display: table;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.fh5co-video a i {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-size: 40px;
}

.fh5co-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.fh5co-video:hover .overlay {
  background: rgba(0, 0, 0, 0.7);
}

.fh5co-video:hover a {
  position: relative;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.fh5co-cover {
  height: 900px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
}

.fh5co-cover .overlay {
  z-index: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}

.fh5co-cover>.fh5co-container {
  position: relative;
  z-index: 10;
}

@media screen and (max-width: 768px) {
  .fh5co-cover {
    height: 600px;
  }
}

.fh5co-cover .display-t,
.fh5co-cover .display-tc {
  height: 900px;
  display: table;
  width: 100%;
}

@media screen and (max-width: 768px) {

  .fh5co-cover .display-t,
  .fh5co-cover .display-tc {
    height: 600px;
  }
}

.fh5co-cover.fh5co-cover-sm {
  height: 600px;
}

@media screen and (max-width: 768px) {
  .fh5co-cover.fh5co-cover-sm {
    height: 400px;
  }
}

.fh5co-cover.fh5co-cover-sm .display-t,
.fh5co-cover.fh5co-cover-sm .display-tc {
  height: 600px;
  display: table;
  width: 100%;
}

@media screen and (max-width: 768px) {

  .fh5co-cover.fh5co-cover-sm .display-t,
  .fh5co-cover.fh5co-cover-sm .display-tc {
    height: 400px;
  }
}

#fh5co-counter,
#fh5co-event {
  height: 850px;
  float: left;
}

#fh5co-counter .display-t,
#fh5co-counter .display-tc,
#fh5co-event .display-t,
#fh5co-event .display-tc {
  height: 700px;
  display: table;
  width: 100%;
}

#fh5co-counter .fh5co-heading h2,
#fh5co-event .fh5co-heading h2 {
  color: #fff;
}

#fh5co-counter .fh5co-heading span,
#fh5co-event .fh5co-heading span {
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 600;
}

#fh5co-counter .overlay,
#fh5co-event .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 768px) {

  #fh5co-counter,
  #fh5co-event {
    height: inherit;
    padding: 7em 0;
  }

  #fh5co-counter .display-t,
  #fh5co-counter .display-tc,
  #fh5co-event .display-t,
  #fh5co-event .display-tc {
    height: inherit;
  }
}

#fh5co-counter .event-wrap,
#fh5co-event .event-wrap {
  border: 2px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.1);
  padding: 30px;
  width: 100%;
  float: left;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {

  #fh5co-counter .event-wrap,
  #fh5co-event .event-wrap {
    margin-bottom: 10px;
  }
}

#fh5co-counter .event-wrap h3,
#fh5co-event .event-wrap h3 {
  font-size: 30px;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  display: block;
  padding-bottom: 20px;
  letter-spacing: 2px;
}

#fh5co-counter .event-wrap p,
#fh5co-counter .event-wrap span,
#fh5co-event .event-wrap p,
#fh5co-event .event-wrap span {
  display: block;
  color: rgba(255, 255, 255, 0.8);
}

#fh5co-counter .event-wrap i,
#fh5co-event .event-wrap i {
  color: white;
  font-size: 20px;
}

#fh5co-counter .event-wrap .event-col,
#fh5co-event .event-wrap .event-col {
  width: 50%;
  float: left;
  margin-bottom: 30px;
}

#fh5co-counter {
  height: 600px;
}

@media screen and (max-width: 768px) {
  #fh5co-counter {
    height: auto;
  }
}

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 1px;
  background-color: #d4d4d4;
  left: 50%;
  margin-left: 0px;
}

@media screen and (max-width: 480px) {
  .timeline:before {
    margin-left: -64px;
  }
}

.timeline>li {
  margin-bottom: 20px;
  position: relative;
}

.timeline>li:before,
.timeline>li:after {
  content: " ";
  display: table;
}

.timeline>li:after {
  clear: both;
}

.timeline>li>.timeline-panel {
  width: 40%;
  float: left;
  border: 1px solid #d4d4d4;
  padding: 30px;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

.timeline>li>.timeline-panel:before {
  position: absolute;
  top: 80px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " ";
}

.timeline>li>.timeline-panel:after {
  position: absolute;
  top: 81px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}

@media screen and (max-width: 480px) {
  .timeline>li>.timeline-panel {
    width: 75% !important;
  }

  .timeline>li>.timeline-panel:before {
    top: 30px;
  }

  .timeline>li>.timeline-panel:after {
    top: 31px;
  }
}

.timeline>li>.timeline-badge {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
  color: #fff;
  width: 160px;
  height: 160px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 16px;
  left: 50%;
  margin-left: -80px;
  background-color: #999999;
  z-index: 100;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

@media screen and (max-width: 480px) {
  .timeline>li>.timeline-badge {
    width: 60px;
    height: 60px;
    margin-left: -20px !important;
  }
}

.timeline>li.timeline-inverted>.timeline-panel {
  float: right;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-title {
  margin-top: 0;
}

.date {
  display: block;
  margin-bottom: 20px;
  font-size: 13px;
}

.timeline-body>p,
.timeline-body>ul {
  margin-bottom: 0;
}

.timeline-body>p+p {
  margin-top: 5px;
}

@media (max-width: 992px) {
  ul.timeline:before {
    left: 90px;
  }

  ul.timeline>li>.timeline-panel {
    width: calc(100% - 200px);
    width: -moz-calc(100% - 200px);
    width: -webkit-calc(100% - 200px);
  }

  ul.timeline>li>.timeline-badge {
    left: 15px;
    margin-left: 0;
    top: 16px;
  }

  ul.timeline>li>.timeline-panel {
    float: right;
  }

  ul.timeline>li>.timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  ul.timeline>li>.timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}

#fh5co-gallery-list {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  float: left;
  padding: 0;
  margin: 0;
  width: 100%;
}

#fh5co-gallery-list li {
  display: block;
  padding: 0;
  margin: 0 0 10px 1%;
  list-style: none;
  min-height: 400px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  float: left;
  clear: left;
  position: relative;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}

@media screen and (max-width: 480px) {
  #fh5co-gallery-list li {
    margin-left: 0;
  }
}

#fh5co-gallery-list li a {
  min-height: 400px;
  padding: 2em;
  position: relative;
  width: 100%;
  display: block;
}

#fh5co-gallery-list li a:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  z-index: 80;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.2);
}

#fh5co-gallery-list li a:hover:before {
  background: rgba(0, 0, 0, 0.5);
}

#fh5co-gallery-list li a .case-studies-summary {
  width: auto;
  bottom: 2em;
  left: 2em;
  right: 2em;
  position: absolute;
  z-index: 100;
}

@media screen and (max-width: 768px) {
  #fh5co-gallery-list li a .case-studies-summary {
    bottom: 1em;
    left: 1em;
    right: 1em;
  }
}

#fh5co-gallery-list li a .case-studies-summary span {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
}

#fh5co-gallery-list li a .case-studies-summary h2 {
  color: #fff;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  #fh5co-gallery-list li a .case-studies-summary h2 {
    font-size: 20px;
  }
}

#fh5co-gallery-list li.one-third {
  width: 32.3%;
}

@media screen and (max-width: 768px) {
  #fh5co-gallery-list li.one-third {
    width: 49%;
  }
}

@media screen and (max-width: 480px) {
  #fh5co-gallery-list li.one-third {
    width: 100%;
  }
}

#map {
  width: 100%;
  height: 500px;
  position: relative;
}

@media screen and (max-width: 768px) {
  #map {
    height: 200px;
  }
}

.fh5co-social-icons {
  margin: 0;
  padding: 0;
}

.fh5co-social-icons li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  display: inline;
}

.fh5co-social-icons li a {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  display: inline;
  /* color: #F14E95; */
  padding-left: 10px;
  padding-right: 10px;
}

.fh5co-social-icons li a i {
  font-size: 20px;
}

.fh5co-contact-info ul {
  padding: 0;
  margin: 0;
}

.fh5co-contact-info ul li {
  padding: 0 0 0 50px;
  margin: 0 0 30px 0;
  list-style: none;
  position: relative;
}

.fh5co-contact-info ul li:before {
  /* color: #F14E95; */
  position: absolute;
  left: 0;
  top: .05em;
  /* font-family: 'icomoon'; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fh5co-contact-info ul li.address:before {
  font-size: 30px;
  content: "\e9d1";
}

.fh5co-contact-info ul li.phone:before {
  font-size: 23px;
  content: "\e9f4";
}

.fh5co-contact-info ul li.email:before {
  font-size: 23px;
  content: "\e9da";
}

.fh5co-contact-info ul li.url:before {
  font-size: 23px;
  content: "\e9af";
}

form label {
  font-weight: normal !important;
}

#fh5co-header .display-tc,
#fh5co-counter .display-tc,
.fh5co-cover .display-tc {
  display: table-cell !important;
  vertical-align: middle;
}

#fh5co-header .display-tc h1,
#fh5co-header .display-tc h2,
#fh5co-counter .display-tc h1,
#fh5co-counter .display-tc h2,
.fh5co-cover .display-tc h1,
.fh5co-cover .display-tc h2 {
  margin: 0;
  padding: 0;
  color: white;
  font-family: 'Tangerine', cursive;
}

#fh5co-header .display-tc h1,
#fh5co-counter .display-tc h1,
.fh5co-cover .display-tc h1 {
  margin-bottom: 0px;
  font-size: 100px;
  line-height: 1.5;
  /* font-family: "Sacramento", Arial, serif; */
}

@media screen and (max-width: 768px) {

  #fh5co-header .display-tc h1,
  #fh5co-counter .display-tc h1,
  .fh5co-cover .display-tc h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 480px) {

  #fh5co-header .display-tc h1,
  #fh5co-counter .display-tc h1,
  .fh5co-cover .display-tc h1 {
    font-size: 45px;
  }
}

#fh5co-header .display-tc h2,
#fh5co-counter .display-tc h2,
.fh5co-cover .display-tc h2 {
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 30px;
  font-family: 'Tangerine', cursive;
}

@media screen and (max-width: 480px) {

  #fh5co-header .display-tc h2,
  #fh5co-counter .display-tc h2,
  .fh5co-cover .display-tc h2 {
    font-size: 24px;
  }
}

#fh5co-header .display-tc .btn,
#fh5co-counter .display-tc .btn,
.fh5co-cover .display-tc .btn {
  padding: 15px 20px;
  background: #fff !important;
  color: #F14E95;
  border: none !important;
  font-size: 14px;
  text-transform: uppercase;
}

#fh5co-header .display-tc .btn:hover,
#fh5co-counter .display-tc .btn:hover,
.fh5co-cover .display-tc .btn:hover {
  background: #fff !important;
  -webkit-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75) !important;
  box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75) !important;
}

#fh5co-counter {
  text-align: center;
}

#fh5co-counter .counter {
  font-size: 50px;
  margin-bottom: 10px;
  color: #fff;
  font-weight: 100;
  display: block;
}

#fh5co-counter .counter-label {
  margin-bottom: 0;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: .1em;
}

@media screen and (max-width: 768px) {
  #fh5co-counter .feature-center {
    margin-bottom: 50px;
  }
}

#fh5co-counter .icon {
  width: 70px;
  height: 70px;
  text-align: center;
  -webkit-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  margin-bottom: 30px;
}

#fh5co-counter .icon i {
  height: 70px;
}

#fh5co-counter .icon i:before {
  display: block;
  text-align: center;
  margin-left: 3px;
}

.simply-countdown {
  /* The countdown */
  margin-bottom: 2em;
}

.simply-countdown>.simply-section {
  /* coutndown blocks */
  display: inline-block;
  width: 100px;
  height: 100px;
  /* background: rgba(241, 78, 149, 0.8); */
  background: rgba(22, 62, 61, 0.8);
  margin: 0 4px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  animation: pulse 1s ease infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.simply-countdown>.simply-section>div {
  /* countdown block inner div */
  display: table-cell;
  vertical-align: middle;
  height: 100px;
  width: 100px;
}

.simply-countdown>.simply-section .simply-amount,
.simply-countdown>.simply-section .simply-word {
  display: block;
  color: white;
  /* amounts and words */
}

.simply-countdown>.simply-section .simply-amount {
  font-size: 30px;
  /* amounts */
}

.simply-countdown>.simply-section .simply-word {
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
  font-size: 12px;
  /* words */
}

#fh5co-testimonial,
#fh5co-services,
#fh5co-started,
#fh5co-footer,
#fh5co-event,
#fh5co-couple-story,
#fh5co-couple,
#fh5co-gallery,
.fh5co-section {
  padding: 7em 0;
  clear: both;
  font-family: 'Tangerine', cursive;

}

@media screen and (max-width: 768px) {

  #fh5co-testimonial,
  #fh5co-services,
  #fh5co-started,
  #fh5co-footer,
  #fh5co-event,
  #fh5co-couple-story,
  #fh5co-couple,
  #fh5co-gallery,
  .fh5co-section {
    padding: 3em 0;
    font-family: 'Tangerine', cursive;
  }
}

.fh5co-section-gray {
  background: rgba(0, 0, 0, 0.04);
}

.couple-wrap {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 768px) {
  .couple-wrap {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .couple-wrap .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .couple-single {
    flex: 1 1 calc(33.33% - 10px);
    max-width: calc(33.33% - 10px);
    box-sizing: border-box;
  }

  .couple-img2 img {
    width: 100% !important;
    height: 250px !important;
  }

  .couple-img3 img {
    width: 100% !important;
    height: 150px !important;
  }

  .couple-text {
    text-align: center;
    padding: 10px;
  }

  .couple-text h4 {
    text-align: center;
    padding: 1px;
  }

  .couple-text p {
    text-align: center;
    padding: 0.5px;
  }

  .social-icon ul {
    justify-content: center;
  }
}

.heart {
  position: absolute;
  top: 4em;
  left: 0;
  right: 0;
  z-index: 99;
  animation: pulse 1s ease infinite;
}

.heart i {
  font-size: 20px;
  background: #fff;
  padding: 20px;
  color: #F14E95;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .heart {
    display: none;
  }
}

.couple-half {
  width: 50%;
  float: left;
}

@media screen and (max-width: 768px) {
  .couple-half {
    width: 100%;
  }
}

.couple-half h3 {
  /* font-family: "Sacramento", Arial, serif; */
  color: #F14E95;
  font-size: 30px;
}

.couple-half .groom,
.couple-half .bride {
  float: left;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

.couple-half .groom img,
.couple-half .bride img {
  width: 150px;
  height: 150px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

@media screen and (max-width: 480px) {

  .couple-half .groom,
  .couple-half .bride {
    width: 100%;
    height: 140px;
  }

  .couple-half .groom img,
  .couple-half .bride img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }
}

.couple-half .groom {
  float: right;
  margin-right: 5px;
}

.couple-half .bride {
  float: left;
  margin-left: 5px;
}

.couple-half .desc-groom {
  padding-right: 180px;
  text-align: right;
}

.couple-half .desc-bride {
  padding-left: 180px;
  text-align: left;
}

@media screen and (max-width: 480px) {

  .couple-half .groom,
  .couple-half .bride {
    margin-left: 0;
    margin-right: 0;
  }

  .couple-half .desc-groom {
    padding-right: 0;
    text-align: center;
  }

  .couple-half .desc-bride {
    padding-left: 0;
    text-align: center;
  }
}

.feature-center {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .feature-center {
    margin-bottom: 50px;
  }
}

.feature-center .icon {
  width: 90px;
  height: 90px;
  background: #efefef;
  display: table;
  text-align: center;
  margin: 0 auto 30px auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.feature-center .icon i {
  display: table-cell;
  vertical-align: middle;
  height: 90px;
  font-size: 40px;
  line-height: 40px;
  color: #F14E95;
}

.feature-center p,
.feature-center h3 {
  margin-bottom: 30px;
}

.feature-center h3 {
  text-transform: uppercase;
  font-size: 18px;
  color: #5d5d5d;
}

.fh5co-heading {
  margin-bottom: 5em;
}

.fh5co-heading.fh5co-heading-sm {
  margin-bottom: 2em;
}

.fh5co-heading h2 {
  font-size: 60px;
  margin-bottom: 10px;
  line-height: 1.5;
  font-weight: bold;
  /* color: #F14E95; */
  color: #163e3d;
  font-family: 'Tangerine', cursive;
}

@media screen and (max-width: 768px) {
  .fh5co-heading h2 {
    font-size: 40px;
  }
}

.fh5co-heading p {
  font-size: 18px;
  line-height: 1.5;
  color: #828282;
}

.fh5co-heading span {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.4);
  font-family: 'Source Sans Pro', sans-serif;
}

#fh5co-testimonial .wrap-testimony {
  position: relative;
}

#fh5co-testimonial .wrap-testimony .testimony-slide {
  text-align: center;
}

#fh5co-testimonial .wrap-testimony .testimony-slide span {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  display: block;
}

#fh5co-testimonial .wrap-testimony .testimony-slide span a.twitter {
  color: #F14E95;
  font-weight: 300;
}

#fh5co-testimonial .wrap-testimony .testimony-slide figure {
  margin-bottom: 10px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  display: inline;
  width: 100px;
  height: 100px;
}

#fh5co-testimonial .wrap-testimony .testimony-slide figure img {
  width: 100px;
  height: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

#fh5co-testimonial .wrap-testimony .testimony-slide blockquote {
  border: none;
  margin: 30px auto;
  width: 50%;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

@media screen and (max-width: 992px) {
  #fh5co-testimonial .wrap-testimony .testimony-slide blockquote {
    width: 100%;
  }
}

#fh5co-testimonial .wrap-testimony .testimony-slide blockquote p {
  font-style: italic;
  color: #8f989f;
  font-size: 20px;
  line-height: 1.6em;
}

#fh5co-testimonial .wrap-testimony .testimony-slide.active {
  display: block;
}

#fh5co-testimonial .wrap-testimony .arrow-thumb {
  position: absolute;
  top: 40%;
  display: block;
  width: 100%;
}

#fh5co-testimonial .wrap-testimony .arrow-thumb a {
  font-size: 32px;
  color: #dadada;
}

#fh5co-testimonial .wrap-testimony .arrow-thumb a:hover,
#fh5co-testimonial .wrap-testimony .arrow-thumb a:focus,
#fh5co-testimonial .wrap-testimony .arrow-thumb a:active {
  text-decoration: none;
}

.feature-left {
  float: left;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}

.feature-left:last-child {
  margin-bottom: 0;
}

.feature-left .icon {
  float: left;
  margin-right: 5%;
  width: 54px;
  height: 54px;
  background: #fff;
  display: table;
  text-align: center;
  -webkit-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .feature-left .icon {
    margin-right: 5%;
  }
}

.feature-left .icon i {
  display: table-cell;
  vertical-align: middle;
  font-size: 30px;
  color: #F14E95;
}

.feature-left .feature-copy {
  float: left;
  width: 80%;
}

@media screen and (max-width: 768px) {
  .feature-left .feature-copy {
    width: 70%;
    float: left;
  }
}

.feature-left .feature-copy h3 {
  text-transform: uppercase;
  font-size: 18px;
  color: #5d5d5d;
  margin-bottom: 10px;
}

#fh5co-started {
  position: relative;
}

#fh5co-started .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
}

#fh5co-started .fh5co-heading h2 {
  color: #fff;
}

#fh5co-started .fh5co-heading p {
  color: rgba(255, 255, 255, 0.5);
}

#fh5co-started .form-control {
  background: rgba(255, 255, 255, 0.2);
  border: none !important;
  color: #fff;
  font-size: 16px !important;
  width: 100%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

#fh5co-started .form-control::-webkit-input-placeholder {
  color: #fff;
}

#fh5co-started .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

#fh5co-started .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

#fh5co-started .form-control:-ms-input-placeholder {
  color: #fff;
}

#fh5co-started .form-control:focus {
  background: rgba(255, 255, 255, 0.3);
}

#fh5co-started .btn {
  height: 54px;
  border: none !important;
  background: #F14E95;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  padding-left: 50px;
  padding-right: 50px;
}

#fh5co-started .form-inline .form-group {
  width: 100% !important;
  margin-bottom: 10px;
}

#fh5co-started .form-inline .form-group .form-control {
  width: 100%;
}

#fh5co-started .fh5co-heading {
  margin-bottom: 30px;
}

#fh5co-started .fh5co-heading h2 {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {

  #fh5co-footer .copyright .pull-left,
  #fh5co-footer .copyright .pull-right {
    float: none !important;
    text-align: center;
  }
}

#fh5co-footer .copyright .block {
  display: block;
}

.owl-carousel .owl-controls .owl-dot span {
  background: #e6e6e6;
}

.owl-carousel .owl-controls .owl-dot span:hover,
.owl-carousel .owl-controls .owl-dot span:focus {
  background: #cccccc;
}

.owl-carousel .owl-controls .owl-dot:hover span,
.owl-carousel .owl-controls .owl-dot:focus span {
  background: #cccccc;
}

.owl-carousel .owl-controls .owl-dot.active span {
  background: transparent;
  border: 2px solid #F14E95;
}

#fh5co-offcanvas {
  position: absolute;
  z-index: 1901;
  width: 270px;
  background: black;
  top: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 45px 40px 40px 40px;
  overflow-y: auto;
  -moz-transform: translateX(270px);
  -webkit-transform: translateX(270px);
  -ms-transform: translateX(270px);
  -o-transform: translateX(270px);
  transform: translateX(270px);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.offcanvas #fh5co-offcanvas {
  -moz-transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
}

#fh5co-offcanvas a {
  color: rgba(255, 255, 255, 0.5);
}

#fh5co-offcanvas a:hover {
  color: rgba(255, 255, 255, 0.8);
}

#fh5co-offcanvas ul {
  padding: 0;
  margin: 0;
}

#fh5co-offcanvas ul li {
  padding: 0;
  margin: 0;
  list-style: none;
}

#fh5co-offcanvas ul li>ul {
  padding-left: 20px;
  display: none;
}

#fh5co-offcanvas ul li.offcanvas-has-dropdown>a {
  display: block;
  position: relative;
}

#fh5co-offcanvas ul li.offcanvas-has-dropdown>a:after {
  position: absolute;
  right: 0px;
  /* font-family: 'icomoon'; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e921";
  font-size: 20px;
  color: rgba(255, 255, 255, 0.2);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

#fh5co-offcanvas ul li.offcanvas-has-dropdown.active a:after {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.uppercase {
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.gototop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.gototop.active {
  opacity: 1;
  visibility: visible;
}

.gototop a {
  width: 50px;
  height: 50px;
  display: table;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

.gototop a i {
  height: 50px;
  display: table-cell;
  vertical-align: middle;
}

.gototop a:hover,
.gototop a:active,
.gototop a:focus {
  text-decoration: none;
  outline: none;
}

.fh5co-nav-toggle {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-decoration: none;
}

.fh5co-nav-toggle.active i::before,
.fh5co-nav-toggle.active i::after {
  background: #444;
}

.fh5co-nav-toggle:hover,
.fh5co-nav-toggle:focus,
.fh5co-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}

.fh5co-nav-toggle i {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 2px;
  color: #252525;
  font: bold 14px/.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #252525;
  transition: all .2s ease-out;
}

.fh5co-nav-toggle i::before,
.fh5co-nav-toggle i::after {
  content: '';
  width: 25px;
  height: 2px;
  background: #252525;
  position: absolute;
  left: 0;
  transition: all .2s ease-out;
}

.fh5co-nav-toggle.fh5co-nav-white>i {
  color: #fff;
  background: #fff;
}

.fh5co-nav-toggle.fh5co-nav-white>i::before,
.fh5co-nav-toggle.fh5co-nav-white>i::after {
  background: #fff;
}

.fh5co-nav-toggle i::before {
  top: -7px;
}

.fh5co-nav-toggle i::after {
  bottom: -7px;
}

.fh5co-nav-toggle:hover i::before {
  top: -10px;
}

.fh5co-nav-toggle:hover i::after {
  bottom: -10px;
}

.fh5co-nav-toggle.active i {
  background: transparent;
}

.fh5co-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.fh5co-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.fh5co-nav-toggle {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 21;
  padding: 6px 0 0 0;
  display: block;
  margin: 0 auto;
  display: none;
  height: 44px;
  width: 44px;
  z-index: 2001;
  border-bottom: none !important;
}

@media screen and (max-width: 768px) {
  .fh5co-nav-toggle {
    display: block;
  }
}

.btn {
  margin-right: 4px;
  margin-bottom: 4px;
  /* font-family: "Work Sans", Arial, sans-serif; */
  font-size: 16px;
  font-weight: 400;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 8px 20px;
}

.btn.btn-md {
  padding: 8px 20px !important;
}

.btn.btn-lg {
  padding: 18px 36px !important;
}

.btn:hover,
.btn:active,
.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary {
  background: #F14E95;
  color: #fff;
  border: 2px solid #F14E95;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background: #f366a3 !important;
  border-color: #f366a3 !important;
}

.btn-primary.btn-outline {
  background: transparent;
  color: #F14E95;
  border: 2px solid #F14E95;
}

.btn-primary.btn-outline:hover,
.btn-primary.btn-outline:focus,
.btn-primary.btn-outline:active {
  background: #F14E95;
  color: #fff;
}

.btn-success {
  background: #5cb85c;
  color: #fff;
  border: 2px solid #5cb85c;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active {
  background: #4cae4c !important;
  border-color: #4cae4c !important;
}

.btn-success.btn-outline {
  background: transparent;
  color: #5cb85c;
  border: 2px solid #5cb85c;
}

.btn-success.btn-outline:hover,
.btn-success.btn-outline:focus,
.btn-success.btn-outline:active {
  background: #5cb85c;
  color: #fff;
}

.btn-info {
  background: #5bc0de;
  color: #fff;
  border: 2px solid #5bc0de;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active {
  background: #46b8da !important;
  border-color: #46b8da !important;
}

.btn-info.btn-outline {
  background: transparent;
  color: #5bc0de;
  border: 2px solid #5bc0de;
}

.btn-info.btn-outline:hover,
.btn-info.btn-outline:focus,
.btn-info.btn-outline:active {
  background: #5bc0de;
  color: #fff;
}

.btn-warning {
  background: #f0ad4e;
  color: #fff;
  border: 2px solid #f0ad4e;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active {
  background: #eea236 !important;
  border-color: #eea236 !important;
}

.btn-warning.btn-outline {
  background: transparent;
  color: #f0ad4e;
  border: 2px solid #f0ad4e;
}

.btn-warning.btn-outline:hover,
.btn-warning.btn-outline:focus,
.btn-warning.btn-outline:active {
  background: #f0ad4e;
  color: #fff;
}

.btn-danger {
  background: #d9534f;
  color: #fff;
  border: 2px solid #d9534f;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active {
  background: #d43f3a !important;
  border-color: #d43f3a !important;
}

.btn-danger.btn-outline {
  background: transparent;
  color: #d9534f;
  border: 2px solid #d9534f;
}

.btn-danger.btn-outline:hover,
.btn-danger.btn-outline:focus,
.btn-danger.btn-outline:active {
  background: #d9534f;
  color: #fff;
}

.btn-outline {
  background: none;
  border: 2px solid gray;
  font-size: 16px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
  box-shadow: none;
}

.btn.with-arrow {
  position: relative;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.btn.with-arrow i {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -8px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.btn.with-arrow:hover {
  padding-right: 50px;
}

.btn.with-arrow:hover i {
  color: #fff;
  right: 18px;
  visibility: visible;
  opacity: 1;
}

.form-control {
  box-shadow: none;
  background: transparent;
  border: 2px solid rgba(0, 0, 0, 0.1);
  height: 54px;
  font-size: 18px;
  font-weight: 300;
}

.form-control:active,
.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #F14E95;
}

.row-pb-md {
  padding-bottom: 4em !important;
}

.row-pb-sm {
  padding-bottom: 2em !important;
}

.fh5co-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(../images/loader.gif) center no-repeat #fff;
}

.js .animate-box {
  opacity: 0;
}

.style6_fancy h1,
h2,
h3 {
  font-family: 'Tangerine', cursive;
}

.style6_fancy p {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}

.style6_fancy h3 {
  font-size: 2.5rem;
  font-family: 'Tangerine', cursive;
}

.style6_fancy .couple-wrap p {
  font-size: 18px;
}

.style6_fancy input::placeholder {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
}

.style6_fancy input {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
}

.style6_fancy span {
  font-size: 20px;
}

.style6_fancy textarea::placeholder {
  font-size: 20px;
}