.footer-area {
  background: url(../../images/footer/pana1.jpg) no-repeat center center / cover;
  padding: 100px 0;
  position: relative;
  z-index: 1;
}

.footer-area:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .5;
  z-index: -1;
}

.footer-content {
  margin: auto;
  text-align: center;
}

.content-sub {
  padding: 50px 80px;
  background: rgba(99, 89, 77, 0.5);
  display: inline-block;
  z-index: 99;
  position: relative;
}

.content-sub:before {
  position: absolute;
  content: "";
  left: -8px;
  top: -6px;
  width: 103%;
  height: 105%;
  border: 1px solid rgba(255,255,255, 0.4);
}

.content-sub h2 {
  font-size: 80px;
  font-family: 'Great Vibes', cursive;
  color: #fff;
}

.content-sub span {
  font-size: 30px;
  color: #dffff9;
  font-family: 'Montserrat', sans-serif;
}
