.couple-img3 {
  overflow: hidden;
  transition: all .3s;
}

.couple-img3 img {
  display: flex;
  justify-self: center;
  width: 350px;
  height: 250px;
  object-fit: cover;
  transform: scale(1);
  transition: all .3s;
}

.couple-wrap2:hover .couple-img3 img {
  transform: scale(1.2);
}

.couple-img2 {
  overflow: hidden;
  transition: all .3s;
}

.couple-img2 img {
  display: flex;
  justify-self: center;
  width: 350px;
  height: 447.84px;
  object-fit: cover;
  transform: scale(1);
  transition: all .3s;
}

.couple-wrap3:hover .couple-img2 img {
  transform: scale(1.2);
}