.count-down-area {
    padding-top: 80px;
}

.countdownwrap {
    text-align: center;
    padding: 40px;
    margin: auto;
    position: relative;
    overflow: hidden;
}

.react-countdown li {
    display: inline-block;
    margin: 0 10px;
    text-align: center;
    position: relative;
    width: 167px;
    padding: 8px;
    height: 135px;
    background-size: cover;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
}

.react-countdown li:after {
    display: none;
}

.react-countdown .text {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 14px !important;
    color: #fff;
}

.react-countdown .digit {
    font-size: 60px !important;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 0;
    color: #fff;
    font-family: 'Great Vibes', cursive;
}

@media (max-width:767px) {
    .react-countdown li {
        margin-bottom: 30px;
    }
}



.count-down-section h2 {
    font-family: "Great Vibes", cursive;
    font-size: 60px;
    color: #fff;
    margin: 0;
}

@media (max-width: 1199px) {
    .count-down-section h2 {
        font-size: 62px;
        font-size: 3.44444rem;
    }
}

@media (max-width: 767px) {
    .count-down-section h2 {
        font-size: 50px;
        font-size: 2.77778rem;
    }
}

.count-down-section h2>span {
    font-size: 30px;
    display: block;
    margin-bottom: 0.6em;
}

.count-down-item2 h2>span {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: normal;
    display: block;
    margin-bottom: 0.6em;
}

@media (max-width: 1199px) {
    .count-down-section h2>span {
        font-size: 25px;
        font-size: 1.38889rem;
    }
}

@media (max-width: 767px) {
    .count-down-section h2>span {
        font-size: 60px;
        font-size: 1.11111rem;
    }
}

@media (max-width: 550px) {
    .count-down-item2 .row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .section-area {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
    }

    .section-area h2 {
        font-size: 24px;
        padding: 10px;
        margin-left: 0;
    }

    .clock-area {
        width: 100%;
        padding: 10px;
        text-align: center;
    }

    .react-countdown {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .react-countdown li {
        width: auto;
        height: auto;
        margin: 5px;
        padding: 5px;
        font-size: 12px;
    }

    .react-countdown .digit {
        font-size: 28px;
    }

    .react-countdown .text {
        font-size: 10px;
    }
}

.section-area {
    background: url(../../images/3.jpg) no-repeat center center / cover;
    padding: 18px 0;
    position: relative;
    z-index: 1;
}

.section-area:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(177, 130, 114, 0.88);
    z-index: -1;
    opacity: 0.6;
}


.section-area h2 {
    font-size: 45px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 17.25px 25px;
    display: inline-block;
    margin-left: 23px;
    color: #fff;
}

.clock-area {
    background: #b18272;
    padding: 20px 5px 7px 20px;
}