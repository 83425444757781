.personal-form-area {
    background: linear-gradient(#b18272, #b18272), transparent center center/cover no-repeat fixed;
    padding: 100px 0;
    position: relative;
    background-attachment: fixed;
    z-index: 1;
}

.personal-form-wrap {
    background: rgba(255, 255, 255, .9);
    padding: 70px;
}

.personal-form-wrap .section-title h2 {
    margin-bottom: 0;
    padding-bottom: 0px;
}

.personal-form-wrap .section-title h2:before {
    display: none;
}

.personal-form-wrap .section-title {
    margin-bottom: 30px;
}

.contact-form input {
    width: 100%;
    height: 45px;
    margin-top: 20px;
    padding-left: 10px;
    background: #fff;
    opacity: 1;
    border: none;
    border-radius: 5px;
}

.mi-select {
    margin-bottom: 10px;
    font-size: 16px;
    color: white;
    background-color: #649E93;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px;
    width: 185px;
    text-align: center;
}

.my-people-select {
    margin-bottom: 10px;
    font-size: 16px;
    color: white;
    background-color: #649E93;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px;
    width: 115px;
    text-align: center;
}

.form-control {
    margin-top: 20px;
    font-size: 16px;
    background: #fff;
}

.couple-picture {
    margin: 10px 0;
}

select.form-control:not([size]):not([multiple]) {
    height: 50px;
    border-radius: 5px;
    color: #666;
    font-size: 14px;
    -webkit-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    -moz-appearance: none;
    background: #fff url(../../../images/select-icon.png) no-repeat calc(100% - 15px) center;
    position: relative;
    border: none;

}

.contact-textarea {
    width: 100%;
    height: 170px;
    margin-top: 20px;
    padding-left: 10px;
    padding-top: 10px;
    border: none;
    border-radius: 5px;
}

.contact-form p {
    color: red;
    font-size: 14px;
}

.section-title p {
    padding-top: 10px;
    color: #5a5858;
    font-family: 'Montserrat', sans-serif;
}

.contact-form button {
    background: none;
    border: none;
    padding: 5px 15px 8px;
    background: #b18272;
    color: #fff;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
}

.guest-2 button#submit {
    background: #9a8c40;
}

.contact-form button:hover {
    background: #3f7267;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */

    color: #666;
    font-size: 14px;
}

::-moz-placeholder {
    /* Firefox 19+ */

    color: #666;
    font-size: 14px;
}

:-ms-input-placeholder {
    /* IE 10+ */

    color: #666;
    font-size: 14px;
}

:-moz-placeholder {
    /* Firefox 18- */

    color: #666;
    font-size: 14px;
}

@media only screen and (max-width: 575px) {
    .no-padding-mob {
        padding: 0%;
    }
}